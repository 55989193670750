import React from "react";
import { graphql } from 'gatsby';
import withRoleRequired from '../lib/withRoleRequired';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { mapEdgesToNodes } from "../lib/helpers";
import { Scenario } from "../types/Global";
import IndexCard from "../components/IndexCard";

interface ScenariosPageProps {
  data: {
    allScenariosCsv: Scenario[]
  }
}

const ScenariosPage: React.FunctionComponent<ScenariosPageProps> = ({ data }) => {
  const scenarios = mapEdgesToNodes(data?.allScenariosCsv)
  return (
    <Layout
      pageHeader="Future Scenarios"
      moreInfo="scenarios"
    >
      <SEO
        keywords={[`aleph`, `dstl`, `future`, `workforce`, `scenarios`]}
        title="Scenarios"
      />
      <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4">
        <div className="mb-8">
          This page enables you to pick from a range of different scenarios relating to the future world of work. Each scenario is set in approximately 2035. The main parameters of these scenarios were developed through a series of analytical exercises, involving an evidence review of information about the future workplace and workforce, a Delphi study which consulted the views of a panel of experts, and a set of workshops where these outputs were formed into six coherent future scenarios.
        </div>
        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {scenarios && scenarios.map((scenario: Scenario) => (
              <IndexCard
                key={scenario.slug}
                link={`/scenarios/${scenario.slug}`}
                imageSource={scenario.image}
                title={scenario.title}
                description={scenario.description}
              />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
{
  allScenariosCsv (
    sort: {
      fields: [priority]
      order: ASC
    }
  ) {
    edges {
      node {
        id
        priority
        title
        slug
        description
        image
      }
    }
  }
}
`

export default withRoleRequired(ScenariosPage);
